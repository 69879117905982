import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { useStore } from "../../lib/store";

import { SiReact } from "react-icons/si";
import { SiGatsby } from "react-icons/si";
import { SiNextdotjs } from "react-icons/si";
import { useSpring, animated } from "react-spring";
import { Button, StyledLink } from "../../lib/ui";

const WebDev: React.FC = () => {
  const { menuLocation } = useStore();

  const styles = useSpring({
    opacity: menuLocation === "webdev" ? 1 : 0,
    transform:
      // menuLocation === "webdev" ? `translate(0%,0%)` : `translate(100%, 0%)`,
      menuLocation === "webdev" ? `scale(1)` : `scale(0)`,
  });

  return (
    <>
      <Wrapper style={styles}>
        <h1>{`<WebDev/>`}</h1>
        <Example>
          <div>
            <StaticImage src="../../images/fastrrr.jpg" alt="Fastrrr" />
          </div>
          <div>
            <h3>Fastrrr - Science Behind Sailing</h3>
            <p>Custom Webshop built using Gatsby.js</p>
            <SiReact size={32} style={{ marginRight: "16px" }} />
            <SiGatsby size={32} />
            <div>
              <a href="https://fastrrr.com" target="_blank" rel="noreferrer">
                <Button>OPEN</Button>
              </a>
            </div>
          </div>
        </Example>

        <Example>
          <div>
            <StaticImage
              src="../../images/thekanjimap.png"
              alt="The Kanji Map"
            />
          </div>
          <div>
            <h3>The Kanji Map</h3>
            <p>Japanese language learning PWA built using Next.js</p>
            <SiReact size={32} style={{ marginRight: "16px" }} />
            <SiNextdotjs size={32} />
            <div>
              <a
                href="https://thekanjimap.com"
                target="_blank"
                rel="noreferrer"
              >
                <Button>OPEN</Button>
              </a>
            </div>
          </div>
        </Example>

        <Example>
          <div>
            <StaticImage src="../../images/compass.png" alt="Compass" />
          </div>
          <div>
            <h3>Compass - Marketing &amp; Consulting</h3>
            <p>Marketing agency website built using Gatsby.js</p>
            <SiReact size={32} style={{ marginRight: "16px" }} />
            <SiGatsby size={32} />
            <div>
              <a
                href="https://compassmarketing.hu"
                target="_blank"
                rel="noreferrer"
              >
                <Button>OPEN</Button>
              </a>
            </div>
          </div>
        </Example>
        <StyledLink to="/">
          <h1>{`<Home/>`}</h1>
        </StyledLink>
      </Wrapper>
    </>
  );
};

export default WebDev;

const Wrapper = styled(animated.div)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 64px;

  @media (max-width: 768px) {
    padding: 40px;
  }
`;

const Example = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  margin-bottom: 96px;

  img {
    filter: grayscale(100%);
    clip-path: polygon(
      32px 0%,
      100% 0%,
      100% calc(100% - 32px),
      calc(100% - 32px) 100%,
      0% 100%,
      0% 32px
    );
  }

  h3 {
    margin-bottom: 32px;
  }
  p {
    margin-bottom: 32px;
  }
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;
