import * as React from "react";
import { Sphere, useTexture } from "@react-three/drei";
import baseUrl from "../../images/moon1024.jpg";
import bumpUrl from "../../images/bump1440.jpg";
import { useFrame } from "react-three-fiber";
const Moon: React.FC = () => {
  const [base, bump] = useTexture([baseUrl, bumpUrl]);

  const mesh = React.useRef<THREE.Mesh>();

  useFrame(() => {
    if (mesh?.current) {
      mesh.current.rotation.y += 0.002;
    }
  });

  return (
    <Sphere ref={mesh} args={[1, 64, 64]}>
      <meshPhysicalMaterial map={base} bumpMap={bump} bumpScale={0.03} />
    </Sphere>
  );
};

export default Moon;
