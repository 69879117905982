import create, { SetState, GetState } from "zustand";
import { devtools, persist } from "zustand/middleware";

type MenuLocation = "index" | "webdev" | "research" | "about";

interface State {
  moonClicked: boolean;
  setMoonClicked: (selection: boolean) => void;
  menuLocation: MenuLocation;
  setMenuLocation: (location: MenuLocation) => void;
}

const Store: (set: SetState<State>, get: GetState<State>) => State = (
  set,
  get
) => ({
  moonClicked: false,
  menuLocation: "index",
  setMoonClicked: (newState) => {
    set((state: State) => ({ ...state, moonClicked: newState }));
  },
  setMenuLocation: (location) => {
    set((state: State) => ({ ...state, menuLocation: location }));
  },
});

export const useStore = create(devtools(Store, { name: "store" }));
