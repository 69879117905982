import React from "react";
import styled from "styled-components";
import { GlobalStyle } from "../lib/ui";

import Scene from "./scene";
import Grid from "./overlays/grid";
import WebDev from "./overlays/webdev";
import Index from "./overlays/index";
import Research from "./overlays/research";
import About from "./overlays/about";

interface Props {
  children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <Main>
        <Scene />
        <Grid />
        <Index />
        <WebDev />
        <Research />
        <About />
        {children}
      </Main>
    </>
  );
};

export default Layout;

const Main = styled.main`
  min-height: 100vh;
  min-height: fill-available;
  min-height: -webkit-fill-available;

  max-width: 100%;
  /* max-width: 100vw; */
  overflow-x: hidden;
`;
