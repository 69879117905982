import React from "react";
import styled from "styled-components";
import {
  SiJavascript,
  SiTypescript,
  SiHtml5,
  SiCss3,
  SiReact,
  SiGatsby,
  SiNextdotjs,
  SiCplusplus,
  SiPython,
  SiPytorch,
  SiOpencv,
  SiLabview,
  SiMarkdown,
} from "react-icons/si";

const Skills: React.FC = () => {
  const iconSize = 24;
  return (
    <Wrapper>
      <Programming>
        <h3>{`<Programming/>`}</h3>
        <ul>
          <li>
            <SiHtml5 size={iconSize} /> HTML
          </li>
          <li>
            <SiCss3 size={iconSize} /> CSS
          </li>
          <li>
            <SiJavascript size={iconSize} /> Javascript
          </li>
          <li>
            <SiTypescript size={iconSize} /> Typescript
            <ul>
              <li>
                <SiReact size={iconSize} /> React
              </li>
              <ul>
                <li>
                  <SiGatsby size={iconSize} /> Gatsby
                </li>
                <li>
                  <SiNextdotjs size={iconSize} /> Next
                </li>
              </ul>
            </ul>
          </li>
          <li>
            <SiCplusplus size={iconSize} /> C++
          </li>
          <li>
            <SiPython size={iconSize} /> Python
            <ul>
              <li>
                <SiPytorch size={iconSize} /> Pytorch
              </li>
            </ul>
          </li>
          <li>
            <SiOpencv size={iconSize} /> OpenCV
          </li>
          <li>
            <SiLabview size={iconSize} /> LabView
          </li>
          <li>
            <SiMarkdown size={iconSize} /> Markdown
          </li>
        </ul>
      </Programming>
      <CadCam>
        <h3>{`<CAD_CAM/>`}</h3>
        <ul>
          <li>SolidWorks</li>
          <li>Fusion 360</li>
        </ul>
      </CadCam>
      <Languages>
        <h3>{`<Languages/>`}</h3>
        <ul>
          <li>Hungarian \native\</li>
          <li>English \fluent\</li>
          <li>Japanese \advanced\</li>
        </ul>
      </Languages>
    </Wrapper>
  );
};

export default Skills;

const Wrapper = styled.div`
  ul {
    list-style: square;
  }

  display: grid;
  grid-template-areas:
    "prog cad"
    "prog lang";

  @media (max-width: 768px) {
    grid-template-areas:
      "prog"
      "cad"
      "lang";
  }
`;

const Programming = styled.div`
  grid-area: prog;
`;
const CadCam = styled.div`
  grid-area: cad;
`;
const Languages = styled.div`
  grid-area: lang;
`;
