import React, { useRef } from "react";
import * as THREE from "three";
import { useFrame } from "react-three-fiber";
import { Text } from "@react-three/drei";

const Title: React.FC = () => {
  const ref = useRef<THREE.Object3D>();
  useFrame(({ camera }) => {
    ref?.current && ref.current.lookAt(camera.position);
  });
  return (
    <Text
      ref={ref}
      position={[0, 1, 4]}
      fontSize={0.5}
      maxWidth={200}
      lineHeight={1.6}
      letterSpacing={0.02}
      textAlign={"center"}
      // font="https://fonts.gstatic.com/s/notosans/v27/o-0NIpQlx3QUlC5A4PNjZhNVatyH.woff"
      // font="https://fonts.gstatic.com/s/oswald/v49/TK3_WkUHHAIjg75cFRf3bXL8LICs1xZosUhiYw.woff"
      // font="https://fonts.gstatic.com/s/spacegrotesk/v12/V8mQoQDjQSkFtoMM3T6r8E7mF71Q-gOoraIAEj4PVnsqPMY.woff"
      // font="https://fonts.gstatic.com/s/michroma/v16/PN_zRfy9qWD8fEagAPg9pT8.woff"
      font="https://fonts.gstatic.com/s/chakrapetch/v9/cIflMapbsEk7TDLdtEz1BwkeJI91SZ_D.woff"
      anchorX="center"
      anchorY="middle"
      fillOpacity={0}
      strokeWidth={"1.0%"}
      strokeColor="#ffffff"
    >
      {`KOVÁCS\nGÁBOR`}
      {/* {`056879\n24875`} */}
    </Text>
  );
};

export default Title;
