import React from "react";
import styled from "styled-components";

const Grid: React.FC = () => {
  return (
    <Wrapper>
      <Inner>
        {[...Array(16)].map((_, i) => (
          <div key={i} />
        ))}
      </Inner>
    </Wrapper>
  );
};

export default Grid;

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 32px;
`;

const Inner = styled.div`
  overflow: hidden;
  pointer-events: none;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "a1 a2 a3 a4"
    "b1 b2 b3 b4"
    "c1 c2 c3 c4"
    "d1 d2 d3 d4";
  width: 100%;
  height: 100%;

  /* visible grid lines */
  border-top: 1px dashed #fff2;
  border-left: 1px dashed #fff2;
  & > div {
    border-bottom: 1px dashed #fff2;
    border-right: 1px dashed #fff2;

    &:hover {
      background-color: #fff2;
    }
  }
`;
