import styled, { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

export const GlobalStyle = createGlobalStyle`

  ${normalize}

  :root{
    --color-background: #000;
    --color-text: #fff8;

    --shadow:
    0.4px 0.8px 1.7px rgba(0, 0, 0, 0.022),
    1px 2px 4px rgba(0, 0, 0, 0.032),
    1.9px 3.8px 7.5px rgba(0, 0, 0, 0.04),
    3.4px 6.7px 13.4px rgba(0, 0, 0, 0.048),
    6.3px 12.5px 25.1px rgba(0, 0, 0, 0.058),
    15px 30px 60px rgba(0, 0, 0, 0.08);

    min-height: 1vw;

  }
  *, *:before, *:after {
    box-sizing: border-box;
    font-family: 'Chakra Petch', sans-serif;
  }

  html {
    font-size: 62.5%;
  }

  html, body {
    max-width: 100%;
    overflow-x: hidden;
  }
  

  body {
    background: var(---color-background);
    color: var(--color-text);
    /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility; */
  }

  html, body, #___gatsby, #gatsby-focus-wrapper {
    max-width: 100vw;
    overflow-x: hidden;
  }


  body {
    min-height: 100vh;
    min-height: fill-available;
    min-height: -webkit-fill-available;
  }
  html {
      height: fill-available;
      height: -webkit-fill-available;
  }



  // * fluid typography calculator: https://websemantics.uk/tools/responsive-font-calculator/
  h1 {
    font-size: 3.5rem;
    @media (min-width: 480px) {
    font-size: calc(3.5rem + ((1vw - 0.48rem) * 4.8611));
    min-height: 0vw;
  }
  @media (min-width: 1200px) {
    font-size: 7rem;
  }
    min-height: 0vw;
    font-weight: 600;
  }
  h2 {
    font-size: 2.5rem;
    @media (min-width: 480px) {
    font-size: calc(2.5rem + ((1vw - 0.48rem) * 2.7778));
    min-height: 0vw;
  }
  @media (min-width: 1200px) {
    font-size: 4.5rem;
  }
    min-height: 0vw;
    font-weight: 600;
  }
  h3 {
    font-size: 2rem;
    @media (min-width: 480px) {
    font-size: calc(2rem + ((1vw - 0.48rem) * 2.2222));
    min-height: 0vw;
  }
  @media (min-width: 1200px) {
    font-size: 3.6rem;
  }
    min-height: 0vw;
    
    font-weight: 600;
  }
  h4, time {
    font-size: 1.8rem;
    @media (min-width: 480px) {
    font-size: calc(1.8rem + ((1vw - 0.48rem) * 0.8333));
    min-height: 0vw;
  }
  @media (min-width: 1200px) {
    font-size: 2.4rem;
  }
    min-height: 0vw;
    font-weight: 600;
  }
  h5 {
    font-size: 1.6rem;
    @media (min-width: 480px) {
    font-size:  calc(1.6rem + ((1vw - 0.48rem) * 0.5556));
    min-height: 0vw;
  }
  @media (min-width: 1200px) {
    font-size: 2rem;
  }
    min-height: 0vw;
    color: var(--color-dark-grey);
    font-weight: 600;
  }
  p, a, input, li, label, textarea, select {
    font-size: 1.6rem;
    @media (min-width: 480px) {
    font-size: calc(1.6rem + ((1vw - 0.48rem) * 0.5556));
    min-height: 0vw;
  }
  @media (min-width: 1200px) {
    font-size: 2rem;
  }
  line-height: 2.8rem;
  @media (min-width: 480px) {
    line-height: calc(2.8rem + ((1vw - 0.48rem) * 1.1111));
    min-height: 0vw;
  }
  @media (min-width: 1200px) {
    line-height: 3.6rem;
  }
  min-height: 0vw;
  }

`;

export const Button = styled.button`
  background-color: var(--color-text);
  color: #000;
  border: 0;
  padding-left: 16px;
  padding-right: 16px;
  margin-top: 24px;
  margin-bottom: 24px;
  cursor: pointer;
  clip-path: polygon(
    10px 0px,
    100% 0%,
    100% calc(100% - 10px),
    calc(100% - 10px) 100%,
    0% 100%,
    0% 10px
  );

  font-size: 1.8rem;
  @media (min-width: 480px) {
    font-size: calc(1.8rem + ((1vw - 0.48rem) * 0.8333));
    min-height: 0vw;
  }
  @media (min-width: 1200px) {
    font-size: 2.4rem;
  }
  min-height: 0vw;
  font-weight: 600;

  transition: background-color 0.5s;
  &:hover {
    background-color: #fff;
  }
`;

import { Link } from "gatsby";

export const StyledLink = styled(Link)`
  text-decoration: underline;
  /* text-decoration: none; */
  color: var(--color-text);
  transition: color 0.5s;

  &:hover {
    color: #fff;
  }
`;
