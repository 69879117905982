import React from "react";
import Layout from "./src/components/layout";

// export const wrapRootElement = ({ element, ...restProps }, ...args) => {
//   return (
//     <Layout name="wrapRootElement" props={restProps} args={args} mode="browser">
//       {element}
//     </Layout>
//   );
// };
export const wrapPageElement = (
  { element, location, ...restProps },
  ...args
) => {
  return (
    <Layout
      name="wrapPageElement"
      location={location}
      props={restProps}
      args={args}
      mode="browser"
    >
      {element}
    </Layout>
  );
};
