import React, { useState, useEffect } from "react";
import * as THREE from "three";
import { useThree, Size } from "react-three-fiber";
import { BlendFunction } from "postprocessing";
import {
  DepthOfField,
  EffectComposer,
  Noise,
  Vignette,
  ChromaticAberration,
  Scanline,
  Bloom,
} from "@react-three/postprocessing";

import useDebounce from "../../lib/useDebounce";

const Effects: React.FC = () => {
  const { size } = useThree();
  // const [state, setState] = useState<Size>(size);

  // useEffect(() => {
  //   console.log("size changed");
  //   console.log(size);
  // }, [size]);

  // const [state, setState] = useState<Size>(size);

  const debouncedSize: Size = useDebounce<Size>(size, 1000);

  // useEffect(() => {
  //   console.log("size changed");
  //   console.log(debouncedSize);
  // }, [debouncedSize]);

  return (
    <EffectComposer>
      <Vignette eskil={true} offset={0} darkness={1.1} />
      {/* <DepthOfField
        focusDistance={0}
        focalLength={0.5}
        bokehScale={1}
        // height={480}
      /> */}
      <Noise opacity={0.9} blendFunction={BlendFunction.MULTIPLY} />
      {/* <Bloom
        // blendFunction={BlendFunction.MULTIPLY}
        luminanceThreshold={0.4}
        luminanceSmoothing={0.9}
      /> */}
      <ChromaticAberration
        blendFunction={BlendFunction.NORMAL}
        offset={
          [
            0.002 * (debouncedSize.height / debouncedSize.width),
            0.001 * (debouncedSize.height / debouncedSize.width),
          ] as unknown as THREE.Vector2
          // new THREE.Vector2(
          //   0.002 * (size.height / size.width),
          //   0.001 * (size.height / size.width)
          // )
        }
      />
      {/* <SMAA /> */}
      {/* <Glitch ratio={0.1} /> */}
      {/* <Scanline opacity={0.1} density={0.5} /> */}
      {/* <Grid scale={0.075} /> */}
      {/* <DotScreen /> */}
      {/* <Pixelation granularity={1.1} /> */}
    </EffectComposer>
  );
};

export default Effects;
