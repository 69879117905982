import React from "react";
import styled from "styled-components";
import { useStore } from "../../lib/store";

import { useSpring, animated } from "react-spring";
import { Button, StyledLink } from "../../lib/ui";

const Research: React.FC = () => {
  const { menuLocation } = useStore();

  const styles = useSpring({
    opacity: menuLocation === "research" ? 1 : 0,
    transform:
      // menuLocation === "research" ? `translate(0%,0%)` : `translate(100%, 0%)`,
      menuLocation === "research" ? `scale(1)` : `scale(0)`,
  });

  return (
    <>
      <Wrapper style={styles}>
        <h1>{`<Research/>`}</h1>
        <h2>Publications</h2>
        <div>
          <time>2020</time>
        </div>
        <p>
          <b>G. Kovacs</b>, Y. Kunii, T. Maeda, H. Hashimoto, &quot;Trajectory
          estimation and position correction for hopping robot navigation using
          monocular camera&quot; in Robomech J 7, 25 (2020).
        </p>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://robomechjournal.springeropen.com/articles/10.1186/s40648-020-00172-3"
        >
          <Button>Open in SpringerOpen</Button>
        </a>
        <div>
          <time>2019</time>
        </div>
        <p>
          <b>G. Kovacs</b>, Y. Kunii, H. Hashimoto, ”Hopping Rover Navigation
          Method for Rugged Environments,” in Recent Innovations in
          Mechatronics, Vol 6, No 1., 2019
        </p>
        <a
          target="_blank"
          href="https://ojs.lib.unideb.hu/rIim/article/view/3921/4041"
          rel="noreferrer"
        >
          <Button>Open in UD publishing platform</Button>
        </a>
        {/* <div>
          <time>2019</time>
        </div> */}
        <p>
          <b>G. Kovacs</b>, Y. Kunii, T. Maeda, H. Hashimoto, &quot;Saliency and
          spatial information-based landmark selection for mobile robot
          navigation in natural environments,&quot; in Advanced Robotics Volume
          33, 2019 - Issue 10, pp. 520-535
        </p>
        <a
          target="_blank"
          href="https://www.tandfonline.com/doi/full/10.1080/01691864.2019.1602564"
          rel="noreferrer"
        >
          <Button>Open in Taylor &#38; Francis Online</Button>
        </a>
        <div>
          <time>2018</time>
        </div>
        <p>
          <b>G. Kovacs</b>, H. Naoaki, Y. Kunii, &quot;Robustness Improvement of
          Long Range Landmark Tracking for Mobile Robots,&quot; in 2018
          IEEE/ASME International Conference on Advanced Intelligent
          Mechatronics (AIM), 2018, pp. 1196 - 1201
        </p>
        <a
          target="_blank"
          href="https://ieeexplore.ieee.org/document/8452368"
          rel="noreferrer"
        >
          <Button>Open in IEEE Xplore Library</Button>
        </a>
        <div>
          <time>2017</time>
        </div>
        <p>
          Y. Kunii, <b>G. Kovacs</b>, H. Naoaki, &quot;Mobile Robot Navigation
          in Natural Environments Using Robust Object Tracking,&quot; in 2017
          IEEE 26th International Symposium On Industrial Electronics (ISIE),
          2017, pp. 1747-1752&quot;
        </p>
        <a
          target="_blank"
          href="http://ieeexplore.ieee.org/document/8001512"
          rel="noreferrer"
        >
          <Button>Open in IEEE Xplore Library</Button>
        </a>
        <StyledLink to="/">
          <h1>{`<Home/>`}</h1>
        </StyledLink>
      </Wrapper>
    </>
  );
};

export default Research;

const Wrapper = styled(animated.div)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 64px;

  button {
    margin-bottom: 64px;
  }

  @media (max-width: 768px) {
    padding: 40px;
  }
`;
