import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { useStore } from "../../lib/store";

import { useSpring, animated } from "react-spring";
import { Button, StyledLink } from "../../lib/ui";

import Timeline from "./about/timeline";
import Skills from "./about/skills";

const About: React.FC = () => {
  const { menuLocation } = useStore();

  const styles = useSpring({
    opacity: menuLocation === "about" ? 1 : 0,
    transform:
      // menuLocation === "about" ? `translate(0%,0%)` : `translate(100%, 0%)`,
      menuLocation === "about" ? `scale(1)` : `scale(0)`,
  });

  return (
    <>
      <Wrapper style={styles}>
        <h1>{`<About/>`}</h1>
        <h2>{`<Introduction/>`}</h2>
        <Intro>
          <div>
            <StaticImage src="../../images/lab.png" alt="Rover" />
          </div>
          <div>
            <p>
              Hi, I am a researcher in computer vision, image processing and
              deep learning, Currently employed at{" "}
              <a
                target="_blank"
                href="http://mplab.sztaki.hu/"
                rel="noreferrer"
              >
                SZTAKI
              </a>
            </p>

            <p>
              I recieved a PhD at{" "}
              <a
                target="_blank"
                href="http://www.hmsl.elect.chuo-u.ac.jp/"
                rel="noreferrer"
              >
                Chuo University
              </a>{" "}
              in Tokyo, researching navigation methods for space exploration
              rovers.
            </p>
            <Link to="/research">
              <Button>More about my research</Button>
            </Link>
            <p>
              I am also passionate about web development and work as a
              freelancer.
            </p>
            <Link to="/webdev">
              <Button>More about my projects</Button>
            </Link>
          </div>
        </Intro>

        <h2>{`<Work & Education/>`}</h2>
        <Timeline />

        <a target="_blank" href="/GaborKovacsCV.pdf">
          <Button>Download CV</Button>
        </a>

        <h2>{`<Skills/>`}</h2>
        <Skills />
        <StyledLink to="/">
          <h1>{`<Home/>`}</h1>
        </StyledLink>
      </Wrapper>
    </>
  );
};

export default About;

const Wrapper = styled(animated.div)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 64px;

  button {
    margin-bottom: 64px;
  }

  @media (max-width: 768px) {
    padding: 40px;
  }
`;

const Intro = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  margin-bottom: 96px;

  img {
    filter: grayscale(100%);
    clip-path: polygon(
      32px 0%,
      100% 0%,
      100% calc(100% - 32px),
      calc(100% - 32px) 100%,
      0% 100%,
      0% 32px
    );
  }

  a {
    color: var(--color-text);
  }

  h3 {
    margin-bottom: 32px;
  }
  p {
    margin-bottom: 32px;
  }
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;
