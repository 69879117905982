import React from "react";
import styled from "styled-components";

const Timeline: React.FC = () => {
  return (
    <Wrapper>
      <li>
        <div className="direction-r">
          <div className="flag-wrapper ">
            <span className="hexa"></span>
            <span className="flag">
              <i className="fa fa-briefcase"></i> SZTAKI, Budapest
            </span>{" "}
            <span className="time-wrapper">
              <span className="time">2020-</span>
            </span>{" "}
          </div>
          <div className="desc">
            <b>Researcher</b> <br />
            Machine Perception Research Laboratory
          </div>
        </div>
      </li>

      <li>
        <div className="direction-l">
          <div className="flag-wrapper ">
            {" "}
            <span className="hexa"></span>{" "}
            <span className="flag">
              <i className="fa fa-graduation-cap"></i> Chuo University, Tokyo
            </span>{" "}
            <span className="time-wrapper">
              <span className="time">2017-2020</span>
            </span>{" "}
          </div>
          <div className="desc">
            <b>Electrical, Electronic, and Communication Engineering PhD</b>{" "}
            <br />
            Space robotics, image processing
          </div>
        </div>
      </li>

      <li>
        <div className="direction-r">
          <div className="flag-wrapper">
            {" "}
            <span className="hexa"></span>{" "}
            <span className="flag">
              <i className="fa fa-briefcase"></i> Robit Inc., Tokyo
            </span>{" "}
            <span className="time-wrapper">
              <span className="time">2016-2020</span>
            </span>{" "}
          </div>
          <div className="desc">
            <b>Mechatronics Engineer</b>
            <br />
            Circuit design, CAD/CAM, Rapid prototyping
          </div>
        </div>
      </li>
      <li>
        <div className="direction-l">
          <div className="flag-wrapper">
            {" "}
            <span className="hexa"></span>{" "}
            <span className="flag">
              <i className="fa fa-graduation-cap"></i> Gakugei University, Tokyo
            </span>{" "}
            <span className="time-wrapper">
              <span className="time">2016</span>
            </span>{" "}
          </div>
          <div className="desc">
            <b>Japanese Government (MEXT) Scholarship</b> <br />
            Language training
          </div>
        </div>
      </li>
      <li>
        <div className="direction-r">
          <div className="flag-wrapper">
            {" "}
            <span className="hexa"></span>{" "}
            <span className="flag">
              <i className="fa fa-briefcase"></i> Thyssenkrupp Presta, Budapest
            </span>{" "}
            <span className="time-wrapper">
              <span className="time">2015-2016</span>
            </span>{" "}
          </div>
          <div className="desc">
            <b>Test Engineer</b> <br />
            Vehicle steering system testing, test procedure development
          </div>
        </div>
      </li>
      <li>
        <div className="direction-l">
          <div className="flag-wrapper">
            {" "}
            <span className="hexa"></span>{" "}
            <span className="flag">
              <i className="fa fa-briefcase"></i> BOSCH, Budapest
            </span>{" "}
            <span className="time-wrapper">
              <span className="time">2014</span>
            </span>{" "}
          </div>
          <div className="desc">
            <b>Test Engineer intern</b>
            <br />
            Engineering Electronic Control Units (EEC), Mechatronic projects
          </div>
        </div>
      </li>
      <li>
        <div className="direction-r">
          <div className="flag-wrapper">
            {" "}
            <span className="hexa"></span>{" "}
            <span className="flag hosszu">
              <i className="fa fa-graduation-cap"></i> Universitat Politècnica
              de Catalunya, Barcelona
            </span>{" "}
            <span className="time-wrapper">
              <span className="time">2014</span>
            </span>{" "}
          </div>
          <div className="desc">
            <b>Erasmus programme</b> <br />
            School of Industrial and Aeronautical Engineering
          </div>
        </div>
      </li>
      <li className="li-bme">
        <div className="direction-l">
          <div className="flag-wrapper">
            {" "}
            <span className="hexa"></span>{" "}
            <span className="flag hosszu">
              {" "}
              <i className="fa fa-graduation-cap"></i> Budapest University of
              Technology and Economics
            </span>{" "}
            <span className="time-wrapper">
              <span className="time">2013-2015</span>
            </span>{" "}
          </div>
          <div className="desc">
            <b>Mechatronics engineer MSc</b>
            <br />
            Department of Mechatronics, Optics and Engineering Informatics{" "}
          </div>
        </div>
      </li>
      <li>
        <div className="direction-r">
          <div className="flag-wrapper">
            {" "}
            <span className="hexa"></span>{" "}
            <span className="flag">
              <i className="fa fa-briefcase"></i> NCT, Budapest
            </span>{" "}
            <span className="time-wrapper">
              <span className="time">2012</span>
            </span>{" "}
          </div>
          <div className="desc">
            <b>Mechatronics Engineer intern</b> <br />
            Assembly and testing of electric motors and CNC machines
          </div>
        </div>
      </li>
      <li>
        <div className="direction-l">
          <div className="flag-wrapper">
            {" "}
            <span className="hexa"></span>{" "}
            <span className="flag hosszu">
              {" "}
              <i className="fa fa-graduation-cap"></i> Budapest University of
              Technology and Economics
            </span>{" "}
            <span className="time-wrapper">
              <span className="time">2009-2013</span>
            </span>{" "}
          </div>
          <div className="desc">
            <b>Mechatronics engineer BSc</b>
            <br />
            Department of Mechatronics, Optics and Engineering Informatics{" "}
          </div>
        </div>
      </li>
    </Wrapper>
  );
};

export default Timeline;

const Wrapper = styled.ul`
  color: #000;
  position: relative;
  padding: 0;
  width: 100%;
  margin-top: 20px;
  list-style-type: none;

  &:before {
    position: absolute;
    left: 50%;
    top: 0;
    content: " ";
    display: block;
    width: 2px;
    height: 100%;
    margin-left: -1px;

    background: #fff0;
    z-index: 5;
  }

  li {
    padding: 2em 0;
    position: relative;
    z-index: 100;
  }
  b {
    /* color: black; */
  }
  .hexa {
    width: 16px;
    height: 2px;
    position: absolute;
    background: var(--color-text);
    z-index: 5;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: -30px;
    margin-top: 0;
  }

  .direction-l,
  .direction-r {
    float: none;
    width: 100%;
    text-align: center;
  }
  .flag-wrapper {
    text-align: center;
    position: relative;
    display: inline-block;
  }
  .flag {
    position: relative;
    display: block;
    background: #fff;
    font-weight: 600;
    z-index: 15;
    padding: 6px 10px;
    text-align: left;
    margin-left: 15px;
    margin-right: 15px;
    font-size: 18px;
  }
  .direction-l .flag:after,
  .direction-r .flag:after {
    content: "";
    position: absolute;
    left: 50%;
    top: -15px;
    height: 0;
    width: 0;
    margin-left: -8px;
    border: solid transparent;
    border-bottom-color: rgb(255, 255, 255);
    border-width: 8px;
    pointer-events: none;
  }
  .direction-l .flag {
    clip-path: polygon(
      10px 0px,
      100% 0%,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0% 100%,
      0% 10px
    );
  }
  .direction-r .flag {
    /* clip-path: polygon(
      0px 0px,
      calc(100% - 10px) 0%,
      100% 10px,
      100% 100%,
      10px 100%,
      0% calc(100% - 10px)
    ); */
    clip-path: polygon(
      10px 0px,
      100% 0%,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0% 100%,
      0% 10px
    );
  }
  .time-wrapper {
    display: block;
    position: relative;
    margin: 0 0 0 0;
    z-index: 14;
    line-height: 1em;
    color: #fff;
  }
  .direction-l .time-wrapper {
    float: none;
  }
  .direction-r .time-wrapper {
    float: none;
  }
  .time {
    /* background: #000; */
    display: inline-block;
    padding: 8px;
  }
  .desc {
    position: relative;
    margin: 1em 0 0 0;
    padding: 1em;
    /* border: 1px solid var(--color-text); */
    background: var(--color-text);
    clip-path: polygon(
      10px 0px,
      100% 0%,
      100% calc(100% - 10px),
      calc(100% - 10px) 100%,
      0% 100%,
      0% 10px
    );

    z-index: 15000;
  }
  .direction-l .desc,
  .direction-r .desc {
    position: relative;
    margin: 1em 1em 0 1em;
    padding: 1em;
    z-index: 15;
  }

  @media (min-width: 768px) {
    &:before {
      background: linear-gradient(
        to bottom,
        #fff0 0%,
        var(--color-text) 2%,
        var(--color-text) 92%,
        #fff0 100%
      );
    }
    width: 660px;
    margin: 0 auto;
    margin-top: 20px;

    li:after {
      content: "";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
    .hexa {
      left: -28px;
      right: auto;
      top: 8px;
    }
    .direction-l .hexa {
      left: auto;
      right: -28px;
    }
    .direction-l {
      position: relative;
      width: 310px;
      float: left;
      text-align: right;
    }
    .direction-r {
      position: relative;
      width: 310px;
      float: right;
      text-align: left;
    }
    .flag-wrapper {
      display: inline-block;
    }
    .flag {
      font-size: 18px;
      margin-left: 0;
      margin-right: 0;
    }
    .direction-l .flag:after {
      left: auto;
      right: -16px;
      top: 30%;
      margin-top: -8px;
      border: solid transparent;
      border-left-color: rgb(254, 254, 254);
      border-width: 8px;
    }
    .direction-r .flag:after {
      top: 30%;
      margin-top: -8px;
      border: solid transparent;
      border-right-color: rgb(254, 254, 254);
      border-width: 8px;
      left: -8px;
    }
    .direction-l .hosszu:after {
      left: auto;
      right: -16px;
      top: 20%;
      margin-top: -8px;
      border: solid transparent;
      border-left-color: rgb(254, 254, 254);
      border-width: 8px;
    }
    .direction-r .hosszu:after {
      top: 20%;
      margin-top: -8px;
      border: solid transparent;
      border-right-color: rgb(254, 254, 254);
      border-width: 8px;
      left: -8px;
    }
    .time-wrapper {
      display: inline-block;
      vertical-align: middle;
      margin: 0;
      margin-left: 10px;
      margin-right: 10px;
    }
    .direction-l .time-wrapper {
      float: left;
    }
    .direction-r .time-wrapper {
      float: right;
    }
    .time {
      padding: 5px 10px;
    }
    .direction-r .desc {
      margin: 1em 0 0 0.75em;
    }
  }

  @media (min-width: 992px) {
    width: 800px;
    margin: 0 auto;
    margin-top: 20px;

    .direction-l {
      position: relative;
      width: 380px;
      float: left;
      text-align: right;
    }
    .direction-r {
      position: relative;
      width: 380px;
      float: right;
      text-align: left;
    }
  }
`;
