import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useStore } from "../../lib/store";
import { useSpring, animated } from "react-spring";
import { StyledLink } from "../../lib/ui";

import {
  FaInstagramSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaLinkedin,
  FaEnvelopeSquare,
} from "react-icons/fa";

const Index: React.FC = () => {
  const { menuLocation } = useStore();

  const styles = useSpring({
    opacity: menuLocation === "index" ? 1 : 0,
    transform:
      menuLocation === "index" ? `translate(0%,0%)` : `translate(-100%, 0%)`,
  });

  const iconSize = 32;

  return (
    <>
      <Wrapper style={styles}>
        <div>
          <StyledLink to="/about">
            <h1>{`<About/>`}</h1>
          </StyledLink>
          <StyledLink to="/webdev">
            <h1>{`<WebDev/>`}</h1>
          </StyledLink>
          <StyledLink to="/research">
            <h1>{`<Research/>`}</h1>
          </StyledLink>
        </div>

        <Socials>
          <a
            target="_blank"
            rel="noreferrer"
            href="mailto:gabor@drgaborkovacs.com"
          >
            <FaEnvelopeSquare size={iconSize} />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/gaborkovacs1990/"
          >
            <FaLinkedin size={iconSize} />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/gabor-kovacs"
          >
            <FaGithubSquare size={iconSize} />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/gaborkovacs1990"
          >
            <FaFacebookSquare size={iconSize} />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/gabor_kovacs_/"
          >
            <FaInstagramSquare size={iconSize} />
          </a>
        </Socials>
      </Wrapper>
    </>
  );
};

export default Index;

const Wrapper = styled(animated.div)`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 64px;

  @media (max-width: 768px) {
    padding: 40px;
  }
`;

const Socials = styled.div`
  position: absolute;
  right: 58px;
  bottom: 64px;

  a {
    all: unset;
    cursor: pointer;
    transition: color 0.2s;
    margin: 0 6px;

    &:hover {
      color: #fff;
    }
  }
`;
