import React from "react";
import * as THREE from "three";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useSpring, config } from "@react-spring/three";
import { animated } from "@react-spring/three";
import { OrbitControls } from "@react-three/drei";
import { useThree } from "@react-three/fiber";

const AnimatedControls = animated(OrbitControls);

type AnimatedCameraProps = {
  position?: THREE.Vector3Tuple;
};

const AnimatedCamera = animated(({ position }: AnimatedCameraProps) => {
  const { camera } = useThree();
  if (position) {
    camera.position.set(...position);
  }
  return null;
});

// eslint-disable-next-line react/display-name
const CameraControls = forwardRef((_, ref) => {
  const {
    camera: { position: cameraPos },
  } = useThree();

  const [active, setActive] = useState(true);

  const { positionSpring } = useSpring({
    config: { ...config.slow, precision: 0.001 },
    from: {
      positionSpring: cameraPos.toArray(),
    },
    onStart() {
      setActive(false);
    },
    onRest() {
      setActive(true);
    },
  });

  const orbitRef = useRef<{ target: THREE.Vector3 }>();

  const { targetSpring } = useSpring({
    config: config.slow,
    from: {
      targetSpring: [0, 0, 0],
    },
    onStart() {
      setActive(false);
    },
    onRest() {
      setActive(true);
    },
  });

  useImperativeHandle(ref, () => ({
    goTo(newPosition: THREE.Vector3Tuple) {
      positionSpring.start({
        from: cameraPos.toArray(),
        to: newPosition,
      });
    },
    lookAt(newTarget: THREE.Vector3Tuple) {
      if (orbitRef.current) {
        targetSpring.start({
          from: orbitRef.current.target.toArray(),
          to: newTarget,
        });
      }
    },
    both(newPosition: THREE.Vector3Tuple, newTarget: THREE.Vector3Tuple) {
      positionSpring.start({
        from: cameraPos.toArray(),
        to: newPosition,
        onRest() {
          return;
        },
      });
      if (orbitRef.current) {
        targetSpring.start({
          from: orbitRef.current.target.toArray(),
          to: newTarget,
        });
      }
    },
  }));

  return (
    <>
      {/* 
    // @ts-ignore */}
      <AnimatedControls
        ref={orbitRef as any}
        enablePan={false}
        enableZoom={active} // TODO false
        enableRotate={active} // TODO false
        target={targetSpring as any}
      />
      <AnimatedCamera position={positionSpring as any} />
    </>
  );
});

export default CameraControls;
